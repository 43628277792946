import { useState, ChangeEvent } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ModalLink } from "../../components/ModalLink";
import { SelectedUser } from "../../features/cms/invitationSlice";
import { SearchForm } from "../../components/SearchForm";
import { useSetSearchParam } from "../../features/shared/searchParamsExtension";
import {
    useDeleteUserMutation,
    useDisableUserMutation,
    useEnableUserMutation,
    useGetUsersQuery,
} from "../../gateways/cms/userApi";
import { selected as usersSelected } from "../../features/cms/invitationSlice";
import MainLayout from "../../layouts/MainLayout";
import produce from "immer";
import Stack from "@mui/material/Stack";
import ButtonMUI from "@mui/material/Button";
import { ArticleSortMenu } from "../../components/ArticleSortMenu";
import { EditMenuMinimum } from "../../components/materials/EditMenuMinimum";
import { confirmWithStyle } from "../../components/MyConfirm";

import { styled } from "@mui/system";
import Color from "../../styles/colors.js";
import VarConst from "../../styles/variables.js";
import iconSort from "../../assets/icons/icon_sort.svg";
import iconEllipsis from "../../assets/icons/icon_ellipsis.svg";
import iconCheckboxOff from "../../assets/icons/icon_checkbox_off.svg";
import iconCheckboxOn from "../../assets/icons/icon_checkbox_on.svg";
import { PaginationLinks } from "../../components/PaginationLinks";

// style
const varCss = {
    userListStaticItemsW: "260px + 24px + 3em + 8em + 9em + 9em + 7em + 6em + 9em + 3em + 24px",
};
const PageTitle = styled("section")`
    display: flex;
    justify-content: space-between;
    padding: 24px;
    // margin-bottom: 16px;

    h1 {
        font-size: 24px;
        font-weight: normal;
        margin: 0;
    }
    a {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 116px;
        height: 32px;
        border-radius: 2px;
        color: #f4f4f4;
        background-color: #3a3e5d;
        border-color: #3a3e5d;

        text-decoration: none;
    }
`;
const PageMain = styled("section")`
    display: flex;
    flex-direction: column;
    padding: 0 24px 0;
    overflow: hidden;
`;
const SearchContent = styled(SearchForm)`
    margin-bottom: 16px;
`;
const BarList = styled("section")`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    font-size: 14px;

    [class$="MuiStack-root"] {
        align-items: center;

        & > :not(style) + :not(style) {
            margin-left: 16px;
        }
    }
`;
const WrapperSticky = styled("section")`
    position: relative;
    overflow: auto;
    margin-bottom: 24px;
`;
const TableCommon = styled("table")`
    table-layout: fixed;
    border-spacing: 0;
    height: 100%;
    width: 100%;
    margin: 0;
    border-left: ${VarConst.border1};
    border-bottom: ${VarConst.border1};

    font-size: 13px;

    thead {
        position: sticky;
        top: 0;
        z-index: 10;

        th {
            min-height: 32px;
            height: 32px;
        }
    }

    // 状態表示
    tr {
        &[title="承認待ち"] {
            background-color: #fffbdf;
        }
        &[title="停止中"] {
            background-color: #f4f4f4;
        }
    }

    tr > th,
    tr > td {
        border-top: ${VarConst.border1};
        border-right: ${VarConst.border1};
        line-height: 1.1;

        &.checkbox {
            width: 3em;
            min-width: 3em;
        }
        &.id {
            width: 8em;
            min-width: 8em;
        }
        &.name {
            width: calc((100vw - (${varCss.userListStaticItemsW})) * 0.3);
        }
        &.email {
            width: calc((100vw - (${varCss.userListStaticItemsW})) * 0.3);
        }
        &.lastLoginAt {
            width: 9em;
            min-width: 9em;
        }
        &.organization {
            width: calc((100vw - (${varCss.userListStaticItemsW})) * 0.3);
        }
        &.authority {
            width: 9em;
            min-width: 9em;
        }
        &.status {
            width: 7em;
            min-width: 7em;
        }
        &.disk {
            width: 6em;
            min-width: 6em;
        }
        &.updatedAt {
            width: 9em;
            min-width: 9em;
        }
        &.function {
            width: 3em;
            min-width: 3em;
        }

        &.checkbox {
            position: relative;

            input[type="checkbox"] {
                position: absolute;
                top: 50%;
                left: 50%;
                z-index: 1;
                width: 24px;
                height: 24px;
                margin: 0;
                opacity: 0;
                cursor: pointer;
                transform: translate(-50%, -50%);

                & + span::before {
                    content: "";
                    display: block;
                    width: 24px;
                    height: 24px;
                    pointer-events: none;
                    background: transparent url(${iconCheckboxOff}) scroll no-repeat center center;
                }
                &:checked + span::before {
                    background-image: url(${iconCheckboxOn});
                }
            }
        }
    }
    tr > th {
        padding: 4px 0 4px 8px;
        background-color: ${Color.bgHead};
        text-align: left;
        font-weight: normal;
        border-bottom: ${VarConst.border1};

        &.function {
            text-align: center;
            padding: 0;
        }
    }
    tr > td {
        height: 40px;
        padding: 4px 8px;

        ul {
            /* display: flex; */
            li {
                display: inline;
            }
            li + li::before {
                content: ", ";
            }
        }

        &.function {
            padding-top: 12px;
            padding-bottom: 12px;

            button {
                height: 24px;
                width: 24px;
                min-width: 24px;
                border: ${VarConst.border1};
                border-width: 2px;
                border-radius: 2px;
                background-image: url(${iconEllipsis});
                background-repeat: no-repeat;
                background-position: center center;
                background-color: #fff;
            }
        }
    }
`;
const FunctionHeader = styled("section")`
    display: flex;
    justify-content: space-between;
    align-items: center;

    b {
        flex: 1;
        font-weight: normal;
    }
    button {
        display: block;
        padding: 0;
        margin: 0;
        height: 24px;
        width: 24px;
        border: ${VarConst.border0};
        overflow: hidden;
        color: transparent;
        background-image: url(${iconSort});
        background-repeat: no-repeat;
        background-position: center center;
        background-color: transparent;

        &.MuiButton-root {
            min-width: 24px;
        }
    }
`;
const Button = styled(ButtonMUI)`
    height: 32px;
    border-radius: 2px;
`;

export function UserListPage() {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const setSearchParam = useSetSearchParam(searchParams, setSearchParams);
    const users = useGetUsersQuery({
        page: Number(searchParams.get("page")) || 1,
        per: 25,
        search: searchParams.get("q") || undefined,
        sort_key: searchParams.get("sortKey") || undefined,
        sort_direction: searchParams.get("sortDirection") || undefined,
    });
    const [userDelete] = useDeleteUserMutation();
    const [userEnable] = useEnableUserMutation();
    const [userDisable] = useDisableUserMutation();
    const [selectedUsers, setSelectedUsers] = useState([] as SelectedUser[]);

    const selectUser = (user: SelectedUser, selected: boolean) => {
        if (selected) {
            setSelectedUsers(
                produce(selectedUsers, (newSelectedUsers) => {
                    if (newSelectedUsers.some((u) => u.id === user.id)) return;
                    newSelectedUsers.push(user);
                })
            );
        } else {
            setSelectedUsers(selectedUsers.filter((u) => u.id !== user.id));
        }
    };

    const handleSelectAll = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            setSelectedUsers(users.data?.data || []);
        } else {
            setSelectedUsers([]);
        }
    };

    const q = searchParams.get("q") || "";
    const handleUserSearch = setSearchParam("q");
    // const handleUserSort = (direction: string) => () => setSearchParam("sortDirection")(direction);
    // const handleUserSortBy = (key: string) => () => setSearchParam("sortKey")(key);
    const handleUserSort = (key: string) => (direction: string) => {
        searchParams.set("sortDirection", direction);
        searchParams.set("sortKey", key);
        setSearchParams(searchParams);
    };
    // const deleteUser = (id: number) => {
    //     // eslint-disable-next-line no-restricted-globals
    //     if (!confirm("ユーザーを削除します。よろしいですか？")) return;
    //     userDelete(id);
    // };
    const deleteUser = async (id: number) => {
        const isConfirmed = await confirmWithStyle({
            title: "削除",
            body: "ユーザーを削除します。よろしいですか？",
            okButtonText: "削除",
            destructive: true,
        });
        if (isConfirmed) {
            userDelete(id);
        }
    };
    // const enableUser = (id: number) => {
    //     // eslint-disable-next-line no-restricted-globals
    //     if (!confirm("停止中のユーザーを有効にします。よろしいですか？")) return;
    //     userEnable(id);
    // };
    const enableUser = async (id: number) => {
        const isConfirmed = await confirmWithStyle({
            title: "有効にする",
            body: "停止中のユーザーを有効にします。よろしいですか？",
            okButtonText: "有効にする",
        });
        if (isConfirmed) {
            userEnable(id);
        }
    };
    // const disableUser = (id: number) => {
    //     // eslint-disable-next-line no-restricted-globals
    //     if (!confirm("ユーザーを停止します。よろしいですか？")) return;
    //     userDisable(id);
    // };
    const disableUser = async (id: number) => {
        const isConfirmed = await confirmWithStyle({
            title: "停止",
            body: "ユーザーを停止します。よろしいですか？",
            okButtonText: "停止",
        });
        if (isConfirmed) {
            userDisable(id);
        }
    };
    // const handleUserBulkDelete = async () => {
    //     // eslint-disable-next-line no-restricted-globals
    //     if (!confirm("ユーザーを削除します。よろしいですか？")) return;
    //     // TODO: まとめて削除するAPIを用意してもらった方がいい
    //     await Promise.all(
    //         selectedUsers.map((u) => {
    //             return userDelete(u.id).unwrap();
    //         })
    //     );
    //     setSelectedUsers([]);
    //     users.refetch();
    // };
    const handleUserBulkDelete = async () => {
        const isConfirmed = await confirmWithStyle({
            title: "削除",
            body: "選択したユーザーを削除します。よろしいですか？",
            okButtonText: "削除",
            destructive: true,
        });
        if (isConfirmed) {
            // TODO: まとめて削除するAPIを用意してもらった方がいい
            await Promise.all(
                selectedUsers.map((u) => {
                    return userDelete(u.id).unwrap();
                })
            );
            setSelectedUsers([]);
            users.refetch();
        }
    };

    const openResendModalWithSelectedUsers = () => {
        dispatch(usersSelected(selectedUsers));
        navigate("/invitations/resend", { state: { backgroundLocation: location } });
    };

    const openResendModal = (users: SelectedUser[]) => {
        dispatch(usersSelected(users));
        navigate("/invitations/resend", { state: { backgroundLocation: location } });
    };

    // DEBUG
    // console.log(users.data);

    return (
        <MainLayout title="ユーザー管理">
            <PageTitle>
                <h1>ユーザー管理</h1>
                <ModalLink to="/invitations/create">新規作成</ModalLink>
            </PageTitle>

            <PageMain>
                <SearchContent defaultValue={q} onSubmit={handleUserSearch} placeholder="キーワード検索" />

                <BarList>
                    <Stack spacing={1} direction="row">
                        <Button type="button" onClick={() => openResendModalWithSelectedUsers()}>
                            {/* まとめて  */}招待する
                        </Button>
                        <Button type="button" onClick={handleUserBulkDelete}>
                            {/* まとめて */}削除
                        </Button>
                    </Stack>

                    <Stack spacing={1} direction="row">
                        <span>選択数: {selectedUsers.length}</span>
                        <span>登録件数: {users.data?.total}</span>
                        <span>{users.data && <PaginationLinks meta={users.data} />}</span>
                    </Stack>
                </BarList>

                {/*
                <button onClick={handleUserSort("asc")}>昇順</button>
                <button onClick={handleUserSort("desc")}>降順</button>

                <button onClick={handleUserSortBy("lastLogin")}>sort by 最終ログイン日時</button>
                <button onClick={handleUserSortBy("organizationId")}>sort by テナント</button>
                <button onClick={handleUserSortBy("authority")}>sort by 権限</button>
                <button onClick={handleUserSortBy("updatedAt")}>sort by 更新日時</button>
                <button onClick={handleUserSortBy("status")}>sort by 状態</button>
                */}
                <WrapperSticky>
                    <TableCommon>
                        <thead>
                            <tr>
                                <th className="cell_1 checkbox">
                                    <input
                                        type="checkbox"
                                        checked={selectedUsers.length > 0}
                                        onChange={handleSelectAll}
                                    />
                                    <span></span>
                                </th>
                                <th className="cell_2 id">管理ID</th>
                                <th className="cell_3 name">名前</th>
                                <th className="cell_4 email">メールアドレス</th>
                                <th className="cell_5 lastLoginAt">
                                    <FunctionHeader>
                                        <b>最終ログイン日時</b>
                                        <ArticleSortMenu onSubmit={handleUserSort("lastLogin")} sortKey="value" />
                                    </FunctionHeader>
                                </th>
                                <th className="cell_6 organization">
                                    <FunctionHeader>
                                        <b>テナント</b>
                                        <ArticleSortMenu onSubmit={handleUserSort("organizationId")} sortKey="value" />
                                    </FunctionHeader>
                                </th>
                                <th className="cell_7 authority">
                                    <FunctionHeader>
                                        <b>権限</b>
                                        <ArticleSortMenu onSubmit={handleUserSort("authority")} sortKey="value" />
                                    </FunctionHeader>
                                </th>
                                <th className="cell_8 status">
                                    <FunctionHeader>
                                        <b>状態</b>
                                        <ArticleSortMenu onSubmit={handleUserSort("status")} sortKey="value" />
                                    </FunctionHeader>
                                </th>
                                <th className="cell_9 disk">空き容量</th>
                                <th className="cell_10 updatedAt">
                                    <FunctionHeader>
                                        <b>更新日時</b>
                                        <ArticleSortMenu onSubmit={handleUserSort("updatedAt")} sortKey="value" />
                                    </FunctionHeader>
                                </th>
                                <th className="cell_11 function"> 操作 </th>
                            </tr>
                        </thead>

                        <tbody>
                            {users.data?.data?.map((user) => (
                                <tr className="UserRow" title={user.status} key={user.id}>
                                    <td className="cell_1 checkbox">
                                        <input
                                            type="checkbox"
                                            value={user.id}
                                            checked={selectedUsers.some((u) => u.id === user.id)}
                                            onChange={(e) => selectUser(user, e.target.checked)}
                                        />
                                        <span></span>
                                    </td>
                                    <td className="id">{user.id}</td>
                                    <td className="name">{user.name}</td>

                                    <td className="email">{user.email}</td>
                                    <td className="lastLoginAt">{user.lastLoginAt}</td>
                                    <td className="organization">
                                        <ul>
                                            {user.organization.map((orgItem) => (
                                                <li key={orgItem.id}>{orgItem.name}</li>
                                            ))}
                                        </ul>
                                    </td>
                                    <td className="authority">
                                        {/* TODO: 組織を区別したほうがいいのか？ */}
                                        {user.organization.map((o) => o.usergroup.name).join("、")}
                                    </td>
                                    <td className="status">{user.status}</td>
                                    <td className="disk">{user.disk.displayFree}</td>
                                    <td className="updatedAt">{user.updatedAt}</td>

                                    <td className="function">
                                        <EditMenuMinimum
                                            menuEdit={<Link to={`/users/${user.id}/edit`}>編集</Link>}
                                            menuSub1={
                                                user.status !== "承認済み" && (
                                                    <button type="button" onClick={() => openResendModal([user])}>
                                                        招待
                                                    </button>
                                                )
                                            }
                                            menuSub2={
                                                user.status === "停止中" ? (
                                                    <button type="button" onClick={() => enableUser(user.id)}>
                                                        有効にする
                                                    </button>
                                                ) : (
                                                    <button type="button" onClick={() => disableUser(user.id)}>
                                                        停止
                                                    </button>
                                                )
                                            }
                                            menuDelete={
                                                <button type="button" onClick={() => deleteUser(user.id)}>
                                                    削除
                                                </button>
                                            }
                                        />
                                        {/*
                                        <Link to={`/users/${user.id}/edit`}>編集</Link>
                                        <button type="button" onClick={() => openResendModal([user])}>招待</button>
                                        <button type="button" onClick={() => disableUser(user.id)}>停止</button>
                                        <button type="button" onClick={() => enableUser(user.id)}>有効にする</button>
                                        <button type="button" onClick={() => deleteUser(user.id)}>削除</button>
                                         */}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </TableCommon>
                </WrapperSticky>
            </PageMain>
        </MainLayout>
    );
}
