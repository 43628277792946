import { ChangeEvent, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams, NavLink } from "react-router-dom";
import ReactLoading from 'react-loading';

import { SearchForm } from "../../components/SearchForm";
//import { SelectArticleDisplayCountPerHourFilter } from "../../components/SelectArticleDisplayCountPerHourFilter";
import { SelectArticleStatusFilter } from "../../components/SelectArticleStatusFilter";
import { SelectArticleTypeFilter } from "../../components/SelectArticleTypeFilter";
//import { useArticleDraft } from "../../features/cms/useArticleMakeDraft";
import { selectOrganizationId } from "../../features/cms/organizationBreadcrumbSlice";
//import { useArticleApprove, useArticleReject } from "../../features/cms/useArticleMakeApprove";
import { useSetSearchParam, useSetSearchParamArray } from "../../features/shared/searchParamsExtension";
import { useRouterModal } from "../../features/shared/useModal";
import {
    useBulkDeleteArticlesMutation,
    useBulkUpdateArticleStatusMutation,
    useGetArticlesQuery,
} from "../../gateways/cms/articleApi";
import { useGetMyUserQuery } from "../../gateways/cms/userApi";
import { Article } from "../../gateways/cms/article";
//import { Article, ArticleContent } from "../../gateways/cms/article";
import MainLayout from "../../layouts/MainLayout";
//import { RootState } from "../../store";
import { PeriodWeekDay } from "../../components/PeriodWeekDay";
import { ArticleSortMenu } from "../../components/ArticleSortMenu";
import { ArticleEditMenu } from "../../components/ArticleEditMenu";
import { confirmWithStyle } from "../../components/MyConfirm";

import { styled } from "@mui/system";
import Stack from "@mui/material/Stack";
import ButtonMUI from "@mui/material/Button";
//import Chip from "@mui/material/Chip";
//import Grid from "@mui/material/Grid";
import { makeStyles } from "@material-ui/styles";

import Color from "../../styles/colors.js";
import VarConst from "../../styles/variables.js";
import iconSort from "../../assets/icons/icon_sort.svg";
import iconEllipsis from "../../assets/icons/icon_ellipsis.svg";
import iconReduce from "../../assets/icons/icon_reduce.svg";
import iconCheckboxOff from "../../assets/icons/icon_checkbox_off.svg";
import iconCheckboxOn from "../../assets/icons/icon_checkbox_on.svg";
import icon_checkbox_disabled from "../../assets/icons/icon_checkbox_disabled.svg";
//import { Article, ArticleStatus } from "../../gateways/cms/article";
import { ArticleStatus } from "../../gateways/cms/article";
import { articleStatusNames } from "../../gateways/cms/article";
import { ImageModal } from "../../components/materials/ImageModal";
import { PaginationLinks } from "../../components/PaginationLinks"

// style
const varCss = {
    articleListStaticItemsW: "260px + 24px + 3em + 5em + 4.5em + 88px + 5em + 5em + 3em + 24px",
};
const PageTitle = styled("section")`
    display: flex;
    justify-content: space-between;
    padding: 24px;
    // margin-bottom: 16px;

    h1 {
        font-size: 24px;
        font-weight: normal;
        margin: 0;
    }
    h3 {
        font-size: 14px;
        font-weight: normal;
        color: red;
        margin: 0;
    }
`;
const PageMain = styled("section")`
    display: flex;
    flex-direction: column;
    padding: 0 24px 0;
    overflow: hidden;
`;
const SearchContent = styled(SearchForm)`
    margin-bottom: 16px;
`;
const BarList = styled("section")`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    font-size: 14px;

    [class$="MuiStack-root"] {
        align-items: center;

        & > :not(style) + :not(style) {
            margin-left: 16px;
        }
    }
`;
const WrapperSticky = styled("section")`
    position: relative;
    overflow: auto;
    margin-bottom: 24px;
`;
const TableCommon = styled("table")`
    table-layout: fixed;
    border-spacing: 0;
    height: 100%;
    margin: 0;
    border-left: ${VarConst.border1};
    border-bottom: ${VarConst.border1};

    font-size: 13px;

    thead {
        position: sticky;
        top: 0;
        z-index: 10;
    }

    tr > th,
    tr > td {
        border-top: ${VarConst.border1};
        border-right: ${VarConst.border1};
        line-height: 1.1;

        &.checkbox {
            min-width: 3em; // 固定
            max-width: 3em; // 固定
            position: relative;

            input[type="checkbox"] {
                position: absolute;
                top: 50%;
                left: 50%;
                z-index: 1;
                width: 24px;
                height: 24px;
                margin: 0;
                opacity: 0;
                cursor: pointer;
                transform: translate( -50%, -50%);
                
                & + span::before {
                    content: "";
                    display: block;
                    width: 24px;
                    height: 24px;
                    pointer-events: none;
                    background: transparent url(${iconCheckboxOff}) scroll no-repeat center center;
                }
                &:checked + span::before {
                    background-image: url(${iconCheckboxOn});
                }
            }
        }
        &.id {
            min-width: 3.5em; // 固定
        }
        &.status {
            min-width: 4.9em; // 固定
        }
        &.thumbnail {
            min-width: 88px; // 固定
            max-width: 88px;
            width: 88px;

            button {
                padding: 0;
            }
        }
        &.title {
            /* min-width: 18em; */
            width: calc((100vw - (${varCss.articleListStaticItemsW})) * 0.17);
        }
        &.public_period {
            /* min-width: 12em; */
            width: calc((100vw - (${varCss.articleListStaticItemsW})) * 0.09);
        }
        &.public_pattern {
            /* min-width: 14em; */
            width: calc((100vw - (${varCss.articleListStaticItemsW})) * 0.13);
        }
        &.public_timezone {
            /* min-width: 6em; */
            width: calc((100vw - (${varCss.articleListStaticItemsW})) * 0.04);
        }
        &.category {
            /* min-width: 10em; */
            width: calc((100vw - (${varCss.articleListStaticItemsW})) * 0.18);
        }
        &.at_least {
            /* min-width: 10em; */
            width: calc((100vw - (${varCss.articleListStaticItemsW})) * 0.1);
        }
        &.delivery_group {
            /* min-width: 15em; */
            width: calc((100vw - (${varCss.articleListStaticItemsW})) * 0.25);
            max-width: 25em;
        }
        &.billing_month {
            /* min-width: 6em; */
            width: calc((100vw - (${varCss.articleListStaticItemsW})) * 0.07);
        }
        &.owner {
            /* min-width: 8em; */
            width: calc((100vw - (${varCss.articleListStaticItemsW})) * 0.08);
        }
        &.created {
            min-width: 5em;
        }
        &.updated {
            min-width: 5em;
        }
        &.function {
            min-width: 3em; // 固定
        }
    }
    tr > th {
        padding: 4px 0 4px 8px;
        background-color: ${Color.bgHead};
        text-align: left;
        font-weight: normal;
        border-bottom: ${VarConst.border1};

        &.function {
            text-align: center;
            padding: 0;
        }
    }
    tr > td {
        height: 100%;
        padding: 4px 8px;

        &.checkbox {
            min-width: 3em; // 固定
            max-width: 3em; // 固定
            position: relative;

            input[type="checkbox"] {
                position: absolute;
                top: 50%;
                left: 50%;
                z-index: 1;
                width: 24px;
                height: 24px;
                margin: 0;
                opacity: 0;
                cursor: pointer;
                transform: translate( -50%, -50%);
                
                & + span::before {
                    content: "";
                    display: block;
                    width: 24px;
                    height: 24px;
                    pointer-events: none;
                    background: transparent url(${iconCheckboxOff}) scroll no-repeat center center;
                }
                &:checked + span::before {
                    background-image: url(${iconCheckboxOn});
                }
                &:disabled + span::before {
                    background: transparent url(${icon_checkbox_disabled}) scroll no-repeat center center;
                }
            }
        }

        &.thumbnail {
            padding: 4px;
            text-align: center;

            img {
                width: auto;
                height: auto;
                max-width: 80px;
                max-height: 80px;
            }
        }
        &.public_pattern {
            padding: 0;
            span {
                b {
                    font-weight: normal;
                    & + b::before {
                        content: ", ";
                    }
                }
            }
        }
        &.at_least {
            text-align: center;

            .MuiChip-root {
                background-color: #9290e1;
                span {
                    padding-left: 1.25em;
                    padding-right: 1.25em;
                }
            }
        }
        &.delivery_group,
        &.billing_month {
            padding: 0;

            & > ul {
                height: 100%;
                font-size: 13px;

                & > li {
                    max-height: 33.3%;
                    min-height: 33.3%;
                    width: 100%;
                    padding: 4px;
                    border-bottom: ${VarConst.border1};
                    white-space: nowrap;

                    &:last-child:nth-of-type(n + 3) {
                        border-bottom: ${VarConst.border0};
                    }
                }
            }
        }
        &.delivery_group {
            & > ul {
                & > li {
                    /* overflow: scroll; */
                    overflow: hidden;
                    text-overflow: ellipsis;

                    b {
                        font-weight: normal;
                        margin-right: 0.5em;
                    }
                    span {
                        & + span::before {
                            content: "、";
                        }
                    }
                    &:hover{
                        white-space: normal;
                        overflow: visible;
                    }
                }
            }
        }
        &.function {
            button {
                // IconButtonに hrefがないので Buttonを使用
                height: 24px;
                width: 24px;
                min-width: 24px;
                border: ${VarConst.border1};
                border-width: 2px;
                border-radius: 2px;
                background-image: url(${iconEllipsis});
                background-repeat: no-repeat;
                background-position: center center;
                background-color: #fff;
            }
        }
    }

    // 状態表示
    tr {
        .status div {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 2em;
            margin: 0 auto;
            color: #fff;
            line-height: 1;

            b {
                writing-mode: vertical-lr;
                font-weight: normal;
            }
        }

        // export const articleStatusNames = ["下書き", "確認中", "差戻", "掲載待ち", "掲載中", "掲載終了", "一時停止中", "削除"];
        // export const articleStatusNames = [status0, status1, status2, status3, status4, status5, status6, status7];
        &.status4,
        &.public {
            .status div {
                background-color: #72a1e2;
            }
        }
        &.status6,
        &.suspended {
            background-color: #f4f4f4;
            .status div {
                background-color: #c3d3ea;
            }
        }
        &.status0,
        &.draft {
            .status div {
                background-color: #bdbdbd;
            }
        }
        &.status3,
        &.beforePeriod {
            .status div {
                background-color: #ffa329;
            }
        }
        &.status1,
        &.confirm {
            .status div {
                background-color: #f3d131;
            }
        }
        &.status2,
        &.sendingBack {
            background-color: #ffe3e3;
            .status div {
                background-color: #f86464;
            }
        }
        &.status5,
        &.outofPeriod {
            background-color: #f4f4f4;
            .status div {
                background-color: #788189;
            }
        }
    }
`;
const FunctionHeader = styled("section")`
    display: flex;
    justify-content: space-between;
    align-items: center;

    b {
        flex: 1;
        font-weight: normal;
    }
    button {
        display: block;
        padding: 0;
        margin: 0;
        height: 24px;
        width: 24px;
        border: ${VarConst.border0};
        overflow: hidden;
        color: transparent;
        background-image: url(${iconSort});
        background-repeat: no-repeat;
        background-position: center center;
        background-color: transparent;

        &.MuiButton-root {
            min-width: 24px;
        }
    }
    &.reduce button {
        background-image: url(${iconReduce});
    }
`;
const ButtonArticle = styled(ButtonMUI)`
    height: 32px;
    border-radius: 2px;
`;
const useStyles = makeStyles({
    createButton: {
        height: '32px',
        borderRadius: '2px',
    },
});

const TagStatus = styled("div")``;
const WeeklyPattern = styled("ul")`
    display: flex;
    align-items: center;
    height: 3em;
    padding: 0;
    border-bottom: ${VarConst.border1};
    list-style: outside none;

    li {
        flex: 1;
        text-align: center;

        &:nth-of-type(1) {
            color: #f00;
        }
        &:nth-of-type(7) {
            color: #00f;
        }
        &.off {
            opacity: 0.1;
        }
    }

    & + span {
        display: flex;
        align-items: center;
        height: 3em;
        padding: 0 8px;
    }
`;

const Loading = styled("section")`
    position: relative;
    left: 45%;
    top: 35%;
    b {
        font-size: 12px;
        color: #A9A9A9;
        font-weight: normal;
    }
`;    

// [コンテンツ管理]

//export const Articles = () => {
export function Articles() {
    const [searchParams, setSearchParams] = useSearchParams();
    const setSearchParam = useSetSearchParam(searchParams, setSearchParams);
    const setSearchParamArray = useSetSearchParamArray(searchParams, setSearchParams);
    const [selectedArticles, setSelectedArticles] = useState<number[]>([]);
    //const organizationId = useSelector((state: RootState) => state.organizationBreadcrumb.id);
    const organizationId = useSelector(selectOrganizationId);

    const articles = useGetArticlesQuery({
        organizationId: organizationId || undefined,
        per: searchParams.get("per") || "50",
        page: searchParams.get("page") || "1",
        sort_key: searchParams.get("sort_key") || undefined,
        sort_direction: searchParams.get("sort_direction") || undefined,
        search: searchParams.get("q") || undefined,
        status: searchParams.getAll("status").join(",") || undefined,
        articleType: searchParams.getAll("articleType").join(",") || undefined,
        //displayCountPerHour: searchParams.getAll("displayCountPerHour").join(","),
    });
    const [articleBulkDelete] = useBulkDeleteArticlesMutation();
    //const articleMakeDraft = useArticleDraft();
    //const articleMakeApprove = useArticleApprove();
    //const articleMakeReject = useArticleReject();
    const openModal = useRouterModal();
    const [articleBulkUpdateStatus] = useBulkUpdateArticleStatusMutation();

    const handleArticleDuplicate = (id: number, modaloption: string, deliveryGroupId: number) => {
        openModal(`/articles/${id}/duplicate/?type=${modaloption}&deliveryGroupId=${deliveryGroupId}`);
    };
    /*
    const handleArticleMakeDraft = (id: number) => {
        if (!window.confirm("コンテンツを下書きに移動してもよろしいですか？")) {
            return;
        }
        articleMakeDraft(id);
    };
    */
    const handleArticleMakeDraft = async (id: number) => {
        const isConfirmed = await confirmWithStyle({
            title: "下書きに移動",
            body: "コンテンツを下書きに移動します。よろしいですか？",
            okButtonText: "下書きに移動",
        });
        if (isConfirmed) {
            //articleMakeDraft(id);
            articleBulkUpdateStatus({ status: ArticleStatus.DRAFT, articleIds: [id]});
        }
    };
    const handleArticleMakeApprove = async (id: number) => {
        const isConfirmed = await confirmWithStyle({
            title: "コンテンツを承認する",
            body: "コンテンツを承認します。よろしいですか？",
            okButtonText: "コンテンツを承認",
        });
        if (isConfirmed) {
            let Ids = [id];
            articleBulkUpdateStatus({ status: ArticleStatus.PUBLISHED, articleIds: Ids});
        }
    };
    const handleArticleMakeReject = async (id: number) => {
        const isConfirmed = await confirmWithStyle({
            title: "コンテンツを差し戻す",
            body: "確認中のコンテンツを差戻します。よろしいですか？",
            okButtonText: "コンテンツを差戻す",
        });
        if (isConfirmed) {
            let Ids = [id];
            articleBulkUpdateStatus({ status: ArticleStatus.REJECTED, articleIds: Ids});
        }
    };
    // const handleArticleBulkMakeDraft = () => {
    //     if (!window.confirm("選択したコンテンツを下書きに移動してもよろしいですか？")) {
    //         return;
    //     }
    //     articleBulkUpdateStatus({ status: ArticleStatus.DRAFT, articleIds: selected.map(Number) });
    // };
    const handleArticleBulkMakeDraft = async () => {
        const isConfirmed = await confirmWithStyle({
            title: "下書きに移動",
            body: "選択したコンテンツを下書きに移動します。よろしいですか？",
            okButtonText: "下書きに移動",
        });
        if (isConfirmed) {
            articleBulkUpdateStatus({ status: ArticleStatus.DRAFT, articleIds: selectedArticles});
        }
    };
    // const handleArticleDelete = async (id: number) => {
    //     if (!window.confirm("コンテンツを削除してもよろしいですか？")) {
    //         return;
    //     }
    //     await articleBulkDelete([id]).unwrap();
    //     articles.refetch();
    // };
    const handleArticleDelete = async (id: number) => {
        const isConfirmed = await confirmWithStyle({
            title: "削除",
            body: "コンテンツを削除します。よろしいですか？",
            okButtonText: "削除",
            destructive: true,
        });
        if (isConfirmed) {
            await articleBulkDelete([id]).unwrap();
            articles.refetch();
        }
    };
    const handleArticleBulkDelete = async () => {
        const isConfirmed = await confirmWithStyle({
            title: "削除",
            body: "選択したコンテンツを削除してもよろしいですか？",
            okButtonText: "削除",
            destructive: true,
        });
        if (isConfirmed) {
            await articleBulkDelete(selectedArticles);
            setSelectedArticles([]);
            articles.refetch();
        }
    };

    const handleSort = (key: string) => (direction: string) => {
        searchParams.set("sort_direction", direction);
        searchParams.set("sort_key", key);
        setSearchParams(searchParams);
    };

    const status: string[] = searchParams.getAll("status");
    const articleType: string[] = searchParams.getAll("articleType");
    //const displayCountPerHour: string[] = searchParams.getAll("displayCountPerHour");

    const articleSearch = setSearchParam("q");
    // const placeholder = setplaceholder("キーワード検索");
    // const articleSort = (sortDirection: string) => () => setSearchParam("sort_direction")(sortDirection);
    // const articleSortBy = (sortKey: string) => () => setSearchParam("sort_key")(sortKey);
    const articleStatusFilter = setSearchParamArray("status") || "50";
    const articleTypeFilter = setSearchParamArray("articleType");
    //const articleDisplayCountPerHourFilter = setSearchParamArray("displayCountPerHour");

    const handleSelect = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            setSelectedArticles([...selectedArticles, Number(e.target.value)]);
        } else {
            setSelectedArticles(selectedArticles.filter((id) => id !== Number(e.target.value)));
        }
    };

    const handleSelectAll = (e: ChangeEvent<HTMLInputElement>) => {
        if (!articles.data) {
            return;
        }
        let selectArticles :number[]= [];
        if (e.target.checked) {
            articles.data?.data?.forEach((article) => {
                if(checkCreatedBy(article)){
                    selectArticles.push(Number(article.id)); 
                }
            });
            setSelectedArticles(selectArticles);
        } else {
            setSelectedArticles([]);
        }
    };
    /*
    const articleContentType = (content: ArticleContent):String[] => {
        let contentType = [""];
        if (content) {
            if(content.type === "minimum-schema"){
                contentType[0] = '回数保証';
                if(content.maxTime){
                    contentType[1] = '最大再生:' + (content.maxTime / 60 | 0) + '分';
                }
                if(content.postingInterval){
                    contentType[2] = '掲載間隔:' + (content.postingInterval / 60 | 0) + '分';
                }
            }
            else{
                contentType[0] = '通常';
            }
        }
        return contentType;
    };
    */

    const user = useGetMyUserQuery();
    const checkCreatedBy = (article: Article):boolean => {
        if(user.data?.checkAdmin) return true;
        if(article.createdBy.id === user.data?.id) return true; 
        return false;
    };
    const checkAdmin = ():boolean => {
        if(user.data?.checkAdmin) return true;
        return false;
    }

    const checkFreeDisk = ():string|null => {
        let freeSize = user.data?.disk.free;
        if(freeSize){
            if(freeSize <= 0){
                return "ディスク使用容量上限に達しました。容量が空くまでコンテンツを投稿できません。";
            }else if(freeSize <= 10 * 1024 * 1024){
                return "ディスク空き容量が10MB以下になりました。空き容量を超えるコンテンツを投稿できません。";
            }
        }
        return null;
    }

    const buttonClasses = useStyles();

    if (articles.isFetching) {
        return (
            <MainLayout title="コンテンツ管理">
                <Loading>
                    <ReactLoading type="bars" color="#ADD8E6" height={'-1%'} width={'8%'} />
                    <b>コンテンツデータ取得中</b>
                </Loading>
            </MainLayout>
        );
    };

    return (
        <MainLayout title="コンテンツ管理">
            <PageTitle>
                <h1>コンテンツ管理</h1>
                {checkFreeDisk() ? (
                    <h3>{checkFreeDisk()}</h3>
                ):(<></>)}
                <ButtonMUI className={buttonClasses.createButton} variant="contained" disableElevation component={NavLink} to="/articles/create">
                    新規作成
                </ButtonMUI>
            </PageTitle>

            <PageMain>
                <SearchContent
                    defaultValue={searchParams.get("q") || ""}
                    onSubmit={articleSearch}
                    placeholder="キーワード検索"
                />

                <BarList>
                    <Stack spacing={1} direction="row">
                        <ButtonArticle
                            variant="text"
                            disabled={selectedArticles.length === 0}
                            onClick={handleArticleBulkMakeDraft}
                        >
                            下書きに移動
                        </ButtonArticle>
                        <ButtonArticle
                            variant="text"
                            disabled={selectedArticles.length === 0}
                            onClick={handleArticleBulkDelete}
                        >
                            削除
                        </ButtonArticle>
                    </Stack>

                    <Stack spacing={1} direction="row">
                        {/* selected articles
                        <span> 選択状態: {selected.join(", ")} </span>
                        */}
                        {/*selected articles count*/}
                        <span> 選択数: {selectedArticles.length} </span>
                        <span> コンテンツ件数: {articles.data?.total} </span>
                        <span>
                            {/* TODO: ページネーションの実データを表示 */}
                            {articles.data && <PaginationLinks meta={articles.data} />}
                        </span>
                    </Stack>
                </BarList>

                <WrapperSticky>
                    <TableCommon>
                        <thead>
                            <tr>
                                <th className="checkbox">
                                    <input
                                        type="checkbox"
                                        checked={selectedArticles.length > 0}
                                        onChange={handleSelectAll}
                                    /><span></span>
                                </th>
                                <th className="id">
                                    <FunctionHeader>
                                        <b>ID</b>
                                        <ArticleSortMenu onSubmit={handleSort("id")} sortKey="id" />
                                    </FunctionHeader>
                                </th>
                                <th className="status">
                                    <FunctionHeader className={`${status.length > 0 && "reduce"}`}>
                                        <b>状態</b>
                                        <ArticleSortMenu
                                            onSubmit={handleSort("status")}
                                            optionMenu={
                                                <SelectArticleStatusFilter
                                                    value={status}
                                                    onChange={articleStatusFilter}
                                                />
                                            }
                                        />
                                    </FunctionHeader>
                                </th>
                                <th className="thumbnail">サムネイル</th>
                                <th className="title">
                                    <FunctionHeader>
                                        <b>タイトル</b>
                                        <ArticleSortMenu onSubmit={handleSort("title")} sortKey="value" />
                                    </FunctionHeader>
                                </th>
                                <th className="public_period">
                                    <FunctionHeader>
                                        <b>期間</b>
                                        <ArticleSortMenu onSubmit={handleSort("period")} sortKey="value" />
                                    </FunctionHeader>
                                </th>
                                <th className="public_pattern">
                                    <FunctionHeader>
                                        <b>繰返しパターン</b>
                                        {/*
                                        <ArticleSortMenu onSubmit={handleSort("periodWeek")} />
                                        <ArticleSortMenu onSubmit={handleSort("periodDay")} />
                                         */}
                                    </FunctionHeader>
                                </th>
                                <th className="public_timezone">
                                    <FunctionHeader>
                                        <b>時間</b>
                                        {/*<ArticleSortMenu onSubmit={handleSort("periodTime")} />*/}
                                    </FunctionHeader>
                                </th>
                                <th className="category">
                                    <FunctionHeader className={`${articleType.length > 0 && "reduce"}`}>
                                        <b>コンテンツタイプ</b>
                                        <ArticleSortMenu
                                            onSubmit={handleSort("articleType")}
                                            optionMenu={
                                                <SelectArticleTypeFilter
                                                    value={articleType}
                                                    onChange={articleTypeFilter}
                                                />
                                            }
                                        />
                                    </FunctionHeader>
                                </th>
                                {/*
                                <th className="at_least">
                                    <FunctionHeader className={`${displayCountPerHour.length > 0 && "reduce"}`}>
                                        <b>掲載回数保証</b>
                                        <ArticleSortMenu
                                            onSubmit={handleSort("displayCountPerHour")}
                                            optionMenu={
                                                <SelectArticleDisplayCountPerHourFilter
                                                    value={displayCountPerHour}
                                                    onChange={articleDisplayCountPerHourFilter}
                                                />
                                            }
                                        />
                                    </FunctionHeader>
                                </th>
                                */}
                                <th className="delivery_group">
                                    <FunctionHeader>
                                        <b>配信先</b> {/*配信先グループ*/}
                                        {/*<ArticleSortMenu onSubmit={handleSort("deliveryGroup")} />*/}
                                    </FunctionHeader>
                                </th>
                                {checkAdmin() && (
                                    <th className="billing_month">
                                        <FunctionHeader>
                                            <b>請求月</b>
                                            {/*<ArticleSortMenu onSubmit={handleSort("billingMonth")} />*/}
                                        </FunctionHeader>
                                    </th>
                                )}
                                <th className="owner">
                                    <FunctionHeader>
                                        <b>所有者</b>
                                        <ArticleSortMenu onSubmit={handleSort("createdBy")} sortKey="id" />
                                    </FunctionHeader>
                                </th>
                                <th className="created">
                                    <FunctionHeader>
                                        <b>作成日時</b>
                                        <ArticleSortMenu onSubmit={handleSort("createdAt")} sortKey="value" />
                                    </FunctionHeader>
                                </th>
                                <th className="updated">
                                    <FunctionHeader>
                                        <b>更新日時</b>
                                        <ArticleSortMenu onSubmit={handleSort("updatedAt")} sortKey="value" />
                                    </FunctionHeader>
                                </th>

                                <th className="function">操作</th>
                            </tr>
                        </thead>

                        <tbody>
                            {articles.data?.data?.map((article) => {
                                return (
                                    // status の値によって変数クラスを変える
                                    // 掲載中:   public
                                    // 一時停止: suspended
                                    // 下書き:   draft
                                    // 掲載待ち: beforePeriod
                                    // 確認中:   confirm
                                    // 差戻:     sendingBack
                                    // 掲載終了: outofPeriod
                                    <tr
                                        key={article.id}
                                        className={`status${articleStatusNames.indexOf(article.status.toString())}`}
                                    >
                                        <td className="checkbox">
                                            <input
                                                type="checkbox"
                                                value={article.id}
                                              //  checked={selectedArticles.some((a) => a.id === article.id)}
                                                checked={selectedArticles.includes(article.id)}
                                                disabled={!checkCreatedBy(article)}
                                                onChange={handleSelect}
                                            />
                                            <span></span>
                                        </td>
                                        <td className="id">{article.id}</td>
                                        <td className="status">
                                            <TagStatus>
                                                <b>{article.status}</b>
                                            </TagStatus>
                                        </td>
                                        <td className="thumbnail">
                                            {article.thumbnailType === 'image' ?
                                                <ImageModal
                                                    imageUrl={`${article.thumbnailData}`}
                                                    imageAlt={`${article.title}`}
                                                >
                                                    <img
                                                        src={`${article.thumbnail}`}
                                                        alt={`${article.title}`}
                                                    />
                                                </ImageModal> 
                                                :
                                                <a href={`${article.thumbnailData}`} download={`${article.fileName}`} target="_blank" rel="noreferrer">
                                                    <img src={`${article.thumbnail}`} alt={`${article.title}`}></img>
                                                </a>
                                            }
                                        </td>
                                        <td className="title">{article.title}</td>
                                        <td className="public_period">
                                            {article.periodDayStart}
                                            <br />〜<br />
                                            {article.periodDayEnd}
                                        </td>
                                        <td className="public_pattern">
                                            <WeeklyPattern>
                                                <PeriodWeekDay value={article.periodWeek} />
                                            </WeeklyPattern>
                                            <span>
                                                {article.periodDay && article.periodDay?.length < 31 ? <>毎月:</> : <>毎日掲載</> }
                                                {article.periodDay && article.periodDay?.length < 31 && 
                                                    article.periodDay?.map((dayNum, day) => (
                                                    <b key={day}>{dayNum}</b>
                                                ))}
                                            </span>
                                        </td>
                                        <td className="public_timezone">
                                            {article.periodTimeStart}
                                            <br />〜<br />
                                            {article.periodTimeEnd}
                                        </td>
                                        <td className="category">{article.schemaGroupTitle}</td>
                                        {/*
                                        <td className="at_least">
                                            <Grid container alignItems="center" >
                                                <Grid item xs={11}>
                                                    {articleContentType(article.content).map((contentType, i) => {
                                                        if (i === 0) {
                                                            return (<Chip label={`${contentType}`} color="primary" size="small" />);
                                                        }
                                                        else {
                                                            return (<label><br />{contentType}</label>);
                                                        }
                                                    })}
                                                </Grid>
                                            </Grid>                                         
                                        </td>
                                        */}
                                        <td className="delivery_group">
                                            <ul>
                                                {article.delivery?.map((deliveryItem) => {
                                                    return (
                                                        <li key={deliveryItem.organizationId}>
                                                            <b>{deliveryItem.name}</b>
                                                            {deliveryItem.deliveryGroup && <>&#40;</>}
                                                            {deliveryItem.deliveryGroup?.map((deliveryGroupItem) => {
                                                                return (
                                                                    <span key={deliveryGroupItem.deliveryGroupId}>
                                                                        {deliveryGroupItem.name}
                                                                    </span>
                                                                );
                                                            })}
                                                            {deliveryItem.deliveryGroup && <>&#41;</>}
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </td>
                                        {checkAdmin() && (
                                            <td className="billing_month">
                                                <ul>
                                                    {article.delivery?.map((deliveryItem) => {
                                                        return (
                                                            <li key={deliveryItem.organizationId}>
                                                                {deliveryItem.billingMonth ? (
                                                                    <>{deliveryItem.billingMonth.replace(/-/g, "/")}</>
                                                                ) : (
                                                                    <>--</>
                                                                )}
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            </td>
                                        )}
                                        <td className="owner">{article.createdBy.name}</td>
                                        <td className="created">{article.createdAt.replace(/-/g, "/")}</td>
                                        <td className="updated">{article.updatedAt.replace(/-/g, "/")}</td>
                                        <td className="function">
                                            <ArticleEditMenu
                                                article={article}
                                                onArticleDuplicate={handleArticleDuplicate}
                                                onArticleDraft={handleArticleMakeDraft}
                                                onArticleApprove={handleArticleMakeApprove}
                                                onArticleReject={handleArticleMakeReject}
                                                onArticleDelte={handleArticleDelete}
                                            />
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </TableCommon>
                </WrapperSticky>
            </PageMain>
        </MainLayout>
    );
}