import { useState, useRef, ChangeEvent } from "react";
import { ArticleSchemaContent } from "../gateways/cms/article";

interface Props {
    onChange: (value: Record<string, string>) => unknown;
    content: ArticleSchemaContent;
    setDiskFree: any;
}

interface MinimumProps {
    onChange: (value: Record<string, string>) => unknown;
    addDuration: any;
    index: number;
    maxTime: number;
    duration: number;
    content: ArticleSchemaContent;
    setDiskFree: any;
}

interface MinimumImageProps {
    onChange: (value: Record<string, string>) => unknown;
    addDuration: any;
    changeDuration: (value: string) => unknown;
    index: number;
    maxTime: number;
    duration: number;
    content: ArticleSchemaContent;
    setDiskFree: any;
}

interface SignageProps {
    onChange: (value: Record<string, string>) => unknown;
    addDuration: any;
    index: number;
    duration: number;
    content: ArticleSchemaContent;
    setDiskFree: any;
}

interface SignageImageProps {
    onChange: (value: Record<string, string>) => unknown;
    addDuration: any;
    changeDuration: (value: string) => unknown;
    index: number;
    duration: number;
    content: ArticleSchemaContent;
    setDiskFree: any;
}

interface SignageURLProps {
    onChange: (value: Record<string, string>) => unknown;
    addDuration: any;
    changeDuration: (value: string) => unknown;
    changeImage: (contentData:string, contentFile: string) => unknown;
    index: number;
    duration: number;
    imageName: string;
    imageData: string;
    url: string;
    content: ArticleSchemaContent;
    setDiskFree: any;
}

interface SignageTextProps {
    onChange: (value: Record<string, string>) => unknown;
    addDuration: any;
    changeDuration: (value: string) => unknown;
    changeImage: (contentData:string, contentFile: string) => unknown;
    index: number;
    duration: number;
    imageName: string;
    imageData: string;
    text: string;
    content: ArticleSchemaContent;
    setDiskFree: any;
}

interface imageSize {
    width: number;
    hight: number;
}

const durationOptions = ['5','10','15','20','25','30','35','40','45','50','55','60'];
const accept_image = 'image/gif, image/jpeg, image/png';
const accept_video = 'video/mp4';
const signageFileSize = 100;  // MB
const minimumFileSize = 100;  // MB
const limitImageSize = { width: 2048, hight: 2048 };  // Pixel

const checkiImageSize = async (file:File) => {
    return new Promise<imageSize>((resolve, reject) => {
        const img = new Image();
    
        img.onload = () => {
          const size:imageSize = {
            width: img.naturalWidth,
            hight: img.naturalHeight,
          };
    
          URL.revokeObjectURL(img.src);
          resolve(size);
        };
    
        img.onerror = (error) => {
          reject(error);
        };
    
        img.src = URL.createObjectURL(file);
    });
};

export function ArticleSchemaItemFileContentControl({ onChange, content, setDiskFree }: Props) {
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const files = e.currentTarget.files;
        if (!files || !files.length) {
            return;
        }
        for (let i = 0; i < files.length; i++) {
            // ファイルサイズが制限を超えた場合
            if (files[i].size > setDiskFree(0)) {
                alert("ファイルサイズがアップロード容量上限を超えています");
            }
            else{
                const s = new FileReader();
                s.onload = () => {
                    onChange({
                        data: String(s.result),
                        filename: files[i].name,
                        mimetype: files[i].type,
                    });
                };
                s.readAsDataURL(files[i]);
                setDiskFree(files[i].size);
            }
            
        }
    };

    return (
        <>
            <input type="file" onChange={handleChange} />
        </>
    );
}

/***
 * 回数保証の画像アップロード
 ***/
export function ArticleSchemaImageContentControl({ onChange, addDuration, changeDuration, index, maxTime, duration, content, setDiskFree }: MinimumImageProps) {
    const [durationTime, setDurationTime] = useState("");
    const [totalTime, setTotalTime] = useState(0);
    const inputRef = useRef<HTMLInputElement>(null);

    /*** 画像掲載時間設定 ***/
    const changeDurationTime = (durationTime: string) => {
        setDurationTime(durationTime);
        const total = addDuration(Number(durationTime), index);
        setTotalTime(Number(total));
        changeDuration(durationTime);
    }

    // 編集の場合
    if(durationTime === "" && duration > 0.0){
        setDurationTime(String(duration));
    }
    // 新規追加の場合
    else if(durationTime === "" && duration === 0.0){
        changeDurationTime("10");
    }

    /*** ファイルサイズ制限設定 ***/
    const maxFileMB = content.limit || minimumFileSize;
    const maxFileSize = maxFileMB * 1024 *1024;

    /*** ファイル選択 ***/
    const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
        const files = e.currentTarget.files;
        // ファイル選択がキャンセルされた場合
        if (!files || !files.length || !inputRef.current?.files) {
            if (inputRef.current?.files) {
                inputRef.current.files = new DataTransfer().files;
                addDuration(0, index);
                setDurationTime("");
            }
            return;
        }

        for (let i = 0; i < files.length; i++) {
            const size = await checkiImageSize(files[i]);
            // 総掲載時間が掲載時間制限を超えた場合
            if(totalTime > maxTime){
                alert("総掲載時間=" + Math.ceil(totalTime) + "秒 制限="+maxTime+"秒を超えています");
                // ファイル情報をリセット
                setDurationTime("");
                if (inputRef.current?.files){ 
                    inputRef.current.files = new DataTransfer().files;
                    addDuration(0, index);
                }
            }
            // ファイルサイズが制限を超えた場合
            else if(files[i].size > maxFileSize) {
                alert("ファイルサイズが制限=" + maxFileMB + "MBを超えています");
                // ファイル情報をリセット
                setDurationTime("");
                if (inputRef.current?.files) {
                    inputRef.current.files = new DataTransfer().files;
                    addDuration(0, index);
                }
            }
            // ファイルサイズが制限を超えた場合
            else if (files[i].size > setDiskFree(0)) {
                alert("ファイルサイズがアップロード容量上限を超えています");
                // ファイル情報をリセット
                setDurationTime("");
                if (inputRef.current?.files) {
                    inputRef.current.files = new DataTransfer().files;
                    addDuration(0, index);
                }
            }
            // 画像サイズがが制限を超えた場合
            else if(size.hight > limitImageSize.hight || size.width > limitImageSize.width) {
                alert("画像サイズが制限=" + limitImageSize.hight + "x"+  limitImageSize.width + " Pixelを超えています (" + size.hight + "x"+  size.width + ")");
                // ファイル情報をリセット
                setDurationTime("");
                if (inputRef.current?.files) {
                    inputRef.current.files = new DataTransfer().files;
                    addDuration(0, index);
                }
            }
            // 総掲載時間とファイルサイズが設定内の場合
            else{
                // リクエスト値の設定
                const s = new FileReader();
                s.onload = () => {
                    onChange({
                        data: String(s.result),
                        filename: files[i].name,
                        mimetype: files[i].type,
                        duration: durationTime,
                        index: String(index),
                        hight: String(size.hight),
                        width: String(size.width),
                    });
                };
                s.readAsDataURL(files[i]);
                setDiskFree(files[i].size);
            }
        }
    };

    return (
        <>
            <select
                value={durationTime}
                onChange={(e) => changeDurationTime(e.target.value)}
                defaultValue={"10"}
            >
                {durationOptions.map((durationOption) => (
                    <option value={durationOption}>掲載時間  {durationOption}秒</option>
                ))}
            </select>

            { durationTime  && ( 
                <label>
                    <input type="file" id="inputFile" onChange={handleChange} accept={accept_image} ref={inputRef} />
                    <b>ファイル選択</b>
                </label>
            ) }
        </>
    );
}

/***
 * 回数保証の動画アップロード
 ***/
export function ArticleSchemaVideoContentControl({ onChange, addDuration, index, maxTime, duration, content, setDiskFree }: MinimumProps) {
    const [durationTime, setDurationTime] = useState(0);
    const inputRef = useRef<HTMLInputElement>(null);

    /*** 掲載時間設定 ***/
    if(durationTime === 0 && duration > 0){
        setDurationTime(duration);
    }

    /*** ファイルサイズ制限設定 ***/
    const maxFileMB = content.limit || minimumFileSize;
    const maxFileSize = maxFileMB * 1024 *1024;

    /*** ファイル選択 ***/
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const files = e.currentTarget.files;
        // ファイル選択がキャンセルされた場合
        if (!files || !files.length || !inputRef.current?.files) {
            if (inputRef.current?.files){ 
                inputRef.current.files = new DataTransfer().files;
                addDuration(0, index);
            }
            return;
        }
        
        for (let i = 0; i < files.length; i++) {
            // VIDEOタグの取得
            const video = document.createElement('video');
            const fileURL = URL.createObjectURL(files[i]);
            video.src = fileURL;
            // VIDEO再生時間の取得
            video.ondurationchange = function () {
                setDurationTime(video.duration);
                // VIDEO再生時間を掲載時間に追加
                const total = addDuration(video.duration, index);
                // VIDEO総掲載時間が掲載時間制限を超えた場合
                if(total > maxTime){
                    alert("総掲載時間=" + Math.ceil(total) + "秒 制限="+maxTime+"秒を超えています");
                    // ファイル情報をリセット
                    setDurationTime(0);
                    if (inputRef.current?.files){ 
                        inputRef.current.files = new DataTransfer().files;
                        addDuration(0, index);
                    }
                // ファイルサイズが制限を超えた場合
                } else if (files[i].size > maxFileSize) {
                    alert("ファイルサイズが制限=" + maxFileMB + "MBを超えています");
                    // ファイル情報をリセット
                    setDurationTime(0);
                    if (inputRef.current?.files) {
                        inputRef.current.files = new DataTransfer().files;
                        addDuration(0, index);
                    }
                }
                // ファイルサイズが制限を超えた場合
                else if (files[i].size > setDiskFree(0)) {
                    alert("ファイルサイズがアップロード容量上限を超えています");
                    // ファイル情報をリセット
                    setDurationTime(0);
                    if (inputRef.current?.files) {
                        inputRef.current.files = new DataTransfer().files;
                        addDuration(0, index);
                    }
                }
                // 総掲載時間とファイルサイズが設定内の場合
                else{
                    // リクエスト値の設定
                    const s = new FileReader();
                    s.onload = () => {
                        onChange({
                            data: String(s.result),
                            filename: files[i].name,
                            mimetype: files[i].type,
                            duration: String(video.duration),
                            index: String(index),
                        });
                    };
                    s.readAsDataURL(files[i]);
                    setDiskFree(files[i].size);
                }
            }
        }
    };

    return (
        <>
            <label>
                <input type="file" id="inputFile" onChange={handleChange} accept={accept_video} ref={inputRef} />
                <b>ファイル選択</b>
            </label>
            { durationTime > 0 && ( <b>掲載時間 {Math.ceil(durationTime)} 秒 </b>) }
        </>
    );
}

/***
 * サイネージ用の画像アップロード
 ***/
export function ArticleSignageImageContentControl({ onChange, addDuration, changeDuration, index, duration, content, setDiskFree }: SignageImageProps) {
    const [durationTime, setDurationTime] = useState("");
    const inputRef = useRef<HTMLInputElement>(null);

    /*** 画像掲載時間設定 ***/
    const changeDurationTime = (durationTime: string) => {
        setDurationTime(durationTime);
        addDuration(Number(durationTime), index);
        changeDuration(durationTime);
    }

    // 編集の場合
    if(durationTime === "" && duration > 0.0){
        setDurationTime(String(duration));
    }
    // 新規追加の場合
    else if(durationTime === "" && duration === 0.0){
        changeDurationTime("10");
    }

    /*** ファイルサイズ制限設定 ***/
    const maxFileMB = content.limit || signageFileSize;
    const maxFileSize = maxFileMB * 1024 *1024;

    /*** ファイル選択 ***/
    const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
        const files = e.currentTarget.files;
        // ファイル選択がキャンセルされた場合
        if (!files || !files.length || !inputRef.current?.files) {
            if (inputRef.current?.files) {
                inputRef.current.files = new DataTransfer().files;
                addDuration(0, index);
                setDurationTime("");
            }
            return;
        }

        for (let i = 0; i < files.length; i++) {
            const size = await checkiImageSize(files[i]);
            // ファイルサイズが制限を超えた場合
            if (files[i].size > maxFileSize) {
                alert("ファイルサイズが制限=" + maxFileMB + "MBを超えています");
                // ファイル情報をリセット
                setDurationTime("");
                if (inputRef.current?.files) {
                    inputRef.current.files = new DataTransfer().files;
                    addDuration(0, index);
                }
            }
            // ファイルサイズが制限を超えた場合
            else if (files[i].size > setDiskFree(0)) {
                alert("ファイルサイズがアップロード容量上限を超えています");
                // ファイル情報をリセット
                setDurationTime("");
                if (inputRef.current?.files) {
                    inputRef.current.files = new DataTransfer().files;
                    addDuration(0, index);
                }
            }
            // 画像サイズが制限を超えた場合
            else if(size.hight > limitImageSize.hight || size.width > limitImageSize.width) {
                alert("画像サイズが制限=" + limitImageSize.hight + "x"+  limitImageSize.width + " Pixelを超えています (" + size.hight + "x"+  size.width + ")");
                // ファイル情報をリセット
                setDurationTime("");
                if (inputRef.current?.files) {
                    inputRef.current.files = new DataTransfer().files;
                    addDuration(0, index);
                }
            }
            // ファイルサイズが設定内の場合
            else {
                // リクエスト値の設定
                const s = new FileReader();
                s.onload = () => {
                    onChange({
                        data: String(s.result),
                        filename: files[i].name,
                        mimetype: files[i].type,
                        duration: durationTime,
                        index: String(index),
                        type: "signage-image",
                        hight: String(size.hight),
                        width: String(size.width),
                    });
                };
                s.readAsDataURL(files[i]);
                setDiskFree(files[i].size);
            }
        }
    };

    return (
        <>
            <select
                value={durationTime}
                onChange={(e) => changeDurationTime(e.target.value)}
                defaultValue={"10"}
            >
                {durationOptions.map((durationOption) => (
                    <option value={durationOption}>掲載時間  {durationOption}秒</option>
                ))}
            </select>

            { durationTime  && ( 
                <label>
                    <input type="file" id="inputImage" onChange={handleChange} accept={accept_image} ref={inputRef} />
                    <b>ファイル選択</b>
                </label>
            ) }
        </>
    );
}

/***
 * サイネージ用の動画アップロード
 ***/
export function ArticleSignageVideoContentControl({ onChange, addDuration, index, duration, content, setDiskFree }: SignageProps) {
    const [durationTime, setDurationTime] = useState(0);
    const inputRef = useRef<HTMLInputElement>(null);

    if(durationTime === 0 && duration > 0){
        //console.log(duration);
        setDurationTime(duration);
    }

    /*** ファイルサイズ制限設定 ***/
    const maxFileMB = content.limit || signageFileSize;
    const maxFileSize = maxFileMB * 1024 *1024;

    /*** ファイル選択 ***/
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const files = e.currentTarget.files;
        // ファイル選択がキャンセルされた場合
        if (!files || !files.length || !inputRef.current?.files) {
            if (inputRef.current?.files){ 
                inputRef.current.files = new DataTransfer().files;
                addDuration(0, index);
            }
            return;
        }
        
        for (let i = 0; i < files.length; i++) {
            // VIDEOタグの取得
            const video = document.createElement('video');
            const fileURL = URL.createObjectURL(files[i]);
            video.src = fileURL;
            // VIDEO再生時間の取得
            video.ondurationchange = function () {
                setDurationTime(video.duration);
                // VIDEO再生時間を掲載時間に追加
                addDuration(video.duration, index);
                // ファイルサイズが制限を超えた場合
                if (files[i].size > maxFileSize) {
                    alert("ファイルサイズが制限=" + maxFileMB + "MBを超えています");
                    // ファイル情報をリセット
                    setDurationTime(0);
                    if (inputRef.current?.files) {
                        inputRef.current.files = new DataTransfer().files;
                        addDuration(0, index);
                    }
                }
                // ファイルサイズが制限を超えた場合
                else if (files[i].size > setDiskFree(0)) {
                    alert("ファイルサイズがアップロード容量上限を超えています");
                    // ファイル情報をリセット
                    setDurationTime(0);
                    if (inputRef.current?.files) {
                        inputRef.current.files = new DataTransfer().files;
                        addDuration(0, index);
                    }
                }
                // ファイルサイズが設定内の場合
                else {
                    // リクエスト値の設定
                    const s = new FileReader();
                    s.onload = () => {
                        onChange({
                            data: String(s.result),
                            filename: files[i].name,
                            mimetype: files[i].type,
                            duration: String(video.duration),
                            index: String(index),
                            type: "signage-video"
                        });
                    };
                    s.readAsDataURL(files[i]);
                    setDiskFree(files[i].size);
                }
            }
        }
    };

    return (
        <>
            <label>
                <input type="file" id="inputVideo" onChange={handleChange} accept={accept_video} ref={inputRef} />
                <b>ファイル選択</b>
            </label>
            { durationTime > 0 && ( <b>掲載時間 {Math.ceil(durationTime)} 秒 </b>) }
        </>
    );
}

/***
 * サイネージ用のURL設定
 ***/
export function ArticleSignageURLContentControl({ onChange, addDuration, changeDuration, changeImage, index, duration, imageName, imageData, url, content, setDiskFree }: SignageURLProps) {
    const [durationTime, setDurationTime] = useState("");
    const [subImage, setSubImage] = useState(imageData || "");
    const [fileName, setFileName] = useState(imageName || "");
    const [imageHight, setImageHight] = useState(0);
    const [imageWidth, setImageWidth] = useState(0);
    const inputRef = useRef<HTMLInputElement>(null);

    /*** URL掲載時間設定 ***/
    const changeDurationTime = (durationTime: string) => {
        setDurationTime(durationTime);
        addDuration(Number(durationTime), index);
        changeDuration(durationTime);
    }

    // 編集の場合
    if(durationTime === "" && duration > 0.0){
        setDurationTime(String(duration));
    }
    // 新規追加の場合
    else if(durationTime === "" && duration === 0.0){
        changeDurationTime("10");
    }

    /*** ファイルサイズ制限設定 ***/
    const maxFileMB = content.limit || signageFileSize;
    const maxFileSize = maxFileMB * 1024 *1024;

    /*** URLエラー時用画像設定 ***/
    const changeSubImage = async (e: ChangeEvent<HTMLInputElement>) => {
        const files = e.currentTarget.files;
        // ファイル選択がキャンセルされた場合
        if (!files || !files.length) {
            setSubImage("");
            return;
        }

        for (let i = 0; i < files.length; i++) {
            const size = await checkiImageSize(files[i]);
            // ファイルサイズが制限を超えた場合
            if (files[i].size > maxFileSize) {
                alert("ファイルサイズが制限=" + maxFileMB + "MBを超えています");
                // ファイル情報をリセット
                setDurationTime("");
                if (inputRef.current?.files) {
                    inputRef.current.files = new DataTransfer().files;
                    addDuration(0, index);
                }
            }
            // ファイルサイズが制限を超えた場合
            else if (files[i].size > setDiskFree(0)) {
                alert("ファイルサイズがアップロード容量上限を超えています");
                // ファイル情報をリセット
                setDurationTime("");
                if (inputRef.current?.files) {
                    inputRef.current.files = new DataTransfer().files;
                    addDuration(0, index);
                }
            }
            // 画像サイズが制限を超えた場合
            else if(size.hight > limitImageSize.hight || size.width > limitImageSize.width) {
                alert("画像サイズが制限=" + limitImageSize.hight + "x"+  limitImageSize.width + " Pixelを超えています (" + size.hight + "x"+  size.width + ")");
                // ファイル情報をリセット
                setDurationTime("");
                if (inputRef.current?.files) {
                    inputRef.current.files = new DataTransfer().files;
                    addDuration(0, index);
                }
            }
            // ファイルサイズが設定内の場合
            else {
                // 画像サイズ設定
                setImageHight(size.hight);
                setImageWidth(size.width);
                // リクエスト値の設定
                const s = new FileReader();
                s.onload = () => {
                    setSubImage(String(s.result));
                    setFileName(files[i].name);
                    // サムネイルの変更
                    changeImage(
                        String(s.result), 
                        files[i].name,
                    );
                };
                onChange({
                    mimetype: "text/plain",
                    type: "signage-url",
                });
                s.readAsDataURL(files[i]);
                setDiskFree(files[i].size);
            }
        }
    };

    /*** ファイル選択 ***/
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        onChange({
            url: String(e.currentTarget.value),
            data: subImage,
            filename: fileName,
            mimetype: "text/plain",
            duration: durationTime,
            index: String(index),
            type: "signage-url",
            hight: String(imageHight),
            width: String(imageWidth),
        });
    }

    return (
        <>
            <select
                value={durationTime}
                onChange={(e) => changeDurationTime(e.target.value)}
                defaultValue={"10"}
            >
                {durationOptions.map((durationOption) => (
                    <option value={durationOption}>掲載時間  {durationOption}秒</option>
                ))}
            </select>

            { durationTime && (
                <label id='image'>
                    <input type="file" id="inputImage" onChange={changeSubImage} accept={accept_image} ref={inputRef} />
                    <b>URLエラー時表示画像選択</b>
                </label>
            ) }
            { subImage  && (
                <label id='url'>
                    <input type="url" id="inputURL" onChange={handleChange} defaultValue={url} placeholder="URL入力" /><br/>
                </label>
            ) }
        </>
    );
}

/***
 * サイネージ用のテキスト設定
 ***/
export function ArticleSignageTextContentControl({ onChange, addDuration, changeDuration, changeImage, index, duration, imageName, imageData, text, content, setDiskFree }: SignageTextProps) {
    const [durationTime, setDurationTime] = useState("");
    const [subImage, setSubImage] = useState(imageData || "");
    const [fileName, setFileName] = useState(imageName || "");
    const [imageHight, setImageHight] = useState(0);
    const [imageWidth, setImageWidth] = useState(0);
    const inputRef = useRef<HTMLInputElement>(null);

    /*** テキスト掲載時間設定 ***/
    const changeDurationTime = (durationTime: string) => {
        setDurationTime(durationTime);
        addDuration(Number(durationTime), index);
        changeDuration(durationTime);
    }

    // 編集の場合
    if(durationTime === "" && duration > 0.0){
        setDurationTime(String(duration));
    }
    // 新規追加の場合
    else if(durationTime === "" && duration === 0.0){
        changeDurationTime("10");
    }

    /*** ファイルサイズ制限設定 ***/
    const maxFileMB = content.limit || signageFileSize;
    const maxFileSize = maxFileMB * 1024 *1024;

    /*** テキストエラー時用画像設定 ***/
    const changeSubImage = async (e: ChangeEvent<HTMLInputElement>) => {
        const files = e.currentTarget.files;
        // ファイル選択がキャンセルされた場合
        if (!files || !files.length) {
            setSubImage("");
            return;
        }

        for (let i = 0; i < files.length; i++) {
            const size = await checkiImageSize(files[i]);
            // ファイルサイズが制限を超えた場合
            if (files[i].size > maxFileSize) {
                alert("ファイルサイズが制限=" + maxFileMB + "MBを超えています");
                // ファイル情報をリセット
                setDurationTime("");
                if (inputRef.current?.files) {
                    inputRef.current.files = new DataTransfer().files;
                    addDuration(0, index);
                }
            }
            // ファイルサイズが制限を超えた場合
            else if (files[i].size > setDiskFree(0)) {
                alert("ファイルサイズがアップロード容量上限を超えています");
                // ファイル情報をリセット
                setDurationTime("");
                if (inputRef.current?.files) {
                    inputRef.current.files = new DataTransfer().files;
                    addDuration(0, index);
                }
            }
            // 画像サイズが制限を超えた場合
            else if(size.hight > limitImageSize.hight || size.width > limitImageSize.width) {
                alert("画像サイズが制限=" + limitImageSize.hight + "x"+  limitImageSize.width + " Pixelを超えています (" + size.hight + "x"+  size.width + ")");
                // ファイル情報をリセット
                setDurationTime("");
                if (inputRef.current?.files) {
                    inputRef.current.files = new DataTransfer().files;
                    addDuration(0, index);
                }
            }
            // ファイルサイズが設定内の場合
            else {
                // 画像サイズ設定
                setImageHight(size.hight);
                setImageWidth(size.width);
                // リクエスト値の設定
                const s = new FileReader();
                s.onload = () => {
                    setSubImage(String(s.result));
                    setFileName(files[i].name);
                    // サムネイルの変更
                    changeImage(
                        String(s.result), 
                        files[i].name,
                    );
                };
                onChange({
                    mimetype: "text/plain",
                    type: "signage-text",
                    hight: String(size.hight),
                    width: String(size.width),
                });
                s.readAsDataURL(files[i]);
                setDiskFree(files[i].size);
            }
        }
    };

    /*** ファイル選択 ***/
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        onChange({
            text: String(e.currentTarget.value),
            data: subImage,
            filename: fileName,
            mimetype: "text/plain",
            duration: durationTime,
            index: String(index),
            type: "signage-text",
            hight: String(imageHight),
            width: String(imageWidth),
        });
    }
    /*** 文字数制限 ***/
    const limitTextLength = (e: ChangeEvent<HTMLInputElement>) => {
        let maxLength = 64; // 文字数の上限
        if (e.currentTarget.value.length > maxLength) {
            alert('文字数制限を超えました。');
            e.currentTarget.value = e.currentTarget.value.substring(0, maxLength);
        }
    }
    return (
        <>
            <select
                value={durationTime}
                onChange={(e) => changeDurationTime(e.target.value)}
                defaultValue={"10"}
            >
                {durationOptions.map((durationOption) => (
                    <option value={durationOption}>掲載時間  {durationOption}秒</option>
                ))}
            </select>

            { durationTime && (
                <label id='image'>
                    <input type="file" id="inputImage" onChange={changeSubImage} accept={accept_image} ref={inputRef} />
                    <b>代替画像選択</b>
                </label>
            ) }
            { subImage  && (
                <label id='text'>
                    <input type="text" id="inputText" maxLength={65} onInput={limitTextLength} onChange={handleChange} defaultValue={text} placeholder="文字列入力" /><br/>
                </label>
            ) }
        </>
    );
}
